import { useCallback } from 'react';
import sortBy from 'sort-by';

import { addDate } from 'my-core/date-utils';
import { useStandardApiRequest } from 'my-core/hooks';

import { faBook, faFilm, faKey, faVideo } from '@fortawesome/pro-regular-svg-icons';

import { registerFreeStudySession } from 'my-actions/StudySessionUserActions';

import officeHoursImg from 'images/artwork/blurb_live-weeklies.svg';
import examPrepLongImg from 'images/product-illustrations/crash-course_long.svg';
import examPrepSquareImg from 'images/product-illustrations/crash-course_square.svg';
import mockExamLongImg from 'images/product-illustrations/mock-exam-walkthrough_long.svg';
import mockExamSquareImg from 'images/product-illustrations/mock-exam-walkthrough_square.svg';
import weeklyLongImg from 'images/product-illustrations/weeklies_long.svg';
import weeklySquareImg from 'images/product-illustrations/weeklies_square.svg';

const BUFFER_TIME = 60 * 60 * 1000; // 1 hour

export function getIsLiveNow(studySession) {
  return !!getLiveSessionDate(studySession);
}
export function getLiveSessionDate(studySession) {
  if (studySession.disable_zoom) return;
  const currentTime = Date.now();
  return studySession.dates.find(
    d =>
      currentTime > new Date(d.start_time).valueOf() - BUFFER_TIME &&
      currentTime < new Date(d.end_time).valueOf() + BUFFER_TIME,
  );
}
export function getStudySessionDateDateStatus(ssd, ss) {
  const currentTime = Date.now();
  const bufferTime = ss?.disable_zoom ? 0 : BUFFER_TIME;
  if (currentTime < new Date(ssd.start_time).valueOf() - bufferTime) return 'upcoming';
  if (currentTime > new Date(ss?.disable_zoom ? ssd.start_time : ssd.end_time).valueOf() + bufferTime) return 'past';
  return 'live';
}
export function getStudySessionStartTime(studySession) {
  return studySession?.dates?.[0]?.start_time;
}
export function getStudySessionEndTime(studySession) {
  return studySession?.dates?.at(-1)?.[studySession?.disable_zoom ? 'start_time' : 'end_time'];
}
export function getStudySessionDateStatus(studySession) {
  if (!studySession?.dates?.length) return 'unknown';
  if (getIsLiveNow(studySession)) return 'live';
  if (new Date() > new Date(getStudySessionEndTime(studySession))) return 'past';
  return 'upcoming';
}

export function getStudySessionSemesterDate(studySession) {
  const date =
    studySession &&
    (studySession.dates?.find(d => d.start_time)?.start_time || studySession.expires_at || studySession.created_at);
  return date && new Date(date);
}

export function getStudySessionInstructor(studySession) {
  return (
    !studySession?.instructor_pending &&
    studySession?.non_student_study_session_users?.find(ssu => ssu.role === 'instructor')?.user
  );
}

export function getStudySessionPricing(studySession, subDiscountPercent) {
  const ssPrice = studySession.price;
  const regPrice = (studySession.regular_price || 0) > ssPrice && studySession.regular_price;
  const price = subDiscountPercent ? (ssPrice * (100 - subDiscountPercent)) / 100 : ssPrice;
  const regularPrice = subDiscountPercent ? regPrice || ssPrice : regPrice;
  return [price, regularPrice];
}

export function isStudySessionUserRegistered(ssu) {
  return !!(ssu && (ssu.status === 'active' || ssu.status === 'pending_payment'));
}

export const EXPIRATION_DEFAULT_DAYS = 21;
export function getIsStudySessionExpired(studySession, studySessionUser) {
  const expirationDate = getStudySessionExpirationDate(studySession, studySessionUser);
  return expirationDate < new Date();
}

export function getStudySessionExpirationDate(studySession, studySessionUser) {
  return (
    studySessionUser?.extended_access_date && isStudySessionUserRegistered(studySessionUser) ?
      new Date(studySessionUser.extended_access_date)
    : studySession?.expires_at ? new Date(studySession.expires_at)
    : addDate(getStudySessionEndTime(studySession), { days: EXPIRATION_DEFAULT_DAYS })
  );
}

export function getStudySessionDateLabel(studySession) {
  return studySession.type === 'weekly_tutorial' ? 'Week' : 'Part';
}

export function getIsStudySessionRecordingOnly(studySession) {
  return !!(
    studySession.disable_zoom ||
    !studySession.dates ||
    new Date(getStudySessionEndTime(studySession)) < new Date()
  );
}

export function getAttachmentTypes(studySession, studySessionDateId) {
  const result = { booklets: [], recordings: [], solutions: [], previews: [] };
  studySession?.attachments?.forEach(a => {
    // eslint-disable-next-line eqeqeq
    if (studySessionDateId === 'all' || a.ssd_id == studySessionDateId) result[`${a.type}s`]?.push(a);
  });
  Object.values(result).forEach(a => a.sort(sortBy('order', 'name')));
  return result;
}

export function getStartLink(zoomId) {
  return `https://us06web.zoom.us/s/${zoomId}`;
}
export function getEditPollsLink(zoomId) {
  return `https://us06web.zoom.us/poll/${zoomId}`;
}

export function useRegisterFreeStudySession(course) {
  const { performRequest, requestStatus } = useStandardApiRequest({
    actionCreator: registerFreeStudySession,
    errorMessage: true,
    successMessage: 'Thanks for signing up. You should receive a confirmation email shortly',
  });
  const registerFreeSession = useCallback(
    sessionId => {
      performRequest(sessionId, course?.id);
    },
    [performRequest, course?.id],
  );

  return [registerFreeSession, requestStatus];
}

export const ATTACHMENT_TYPES_CONFIG = {
  booklet: { icon: faBook, label: 'Booklet', color: 'secondary' },
  solution: { icon: faKey, label: 'Solution', color: 'skyblue' },
  recording: { icon: faVideo, label: 'Recording', color: 'cyan' },
  preview: { icon: faFilm, label: 'Preview', color: 'purple' },
};

export const STUDY_SESSION_TYPES = {
  general: {
    slug: 'general',
    label: 'General',
    description: 'General discussion about your course with a Wizeprep prof',
    color: 'primary',
    image: examPrepSquareImg,
  },
  exam_prep: {
    slug: 'exam-prep',
    label: 'Crash Course',
    description:
      'An intensive exam review session where our experienced instructor will recap all testable concepts, run through tons of exam-like problems, answer all of your questions, and give you helpful exam tips and tricks so you can confidently walk into your exam.',
    color: 'cyan',
    image: examPrepSquareImg,
    banner: examPrepLongImg,
  },
  weekly_tutorial: {
    slug: 'weekly-tutorials',
    label: 'Weekly Tutorial',
    description:
      'Mini sessions where an instructor who knows your course inside and out will summarize the topics taught in class that week to help you keep up with your assignments, quizzes, and exams.',
    color: 'primary',
    image: weeklySquareImg,
    banner: weeklyLongImg,
  },
  office_hours: {
    slug: 'office-hours',
    label: 'Office Hours',
    description: 'Clarify your doubts and questions with answers from an experienced instructor.',
    color: 'secondary',
    image: officeHoursImg,
  },
  exam_review: {
    slug: 'mock-exams',
    label: 'Mock Exam Walkthrough',
    description:
      'Walk through a mock exam that is similar to past exams for your course so you can get specific exam tips from our experienced instructor.',
    color: 'secondary',
    image: mockExamSquareImg,
    banner: mockExamLongImg,
  },
};
