import standardApiRequestAction from 'my-core/standardApiRequestAction';

export function fetchStudySessionStudySessionUsers(fetchParams, requestKey, page) {
  const { study_session_id, ...body } = fetchParams;
  return standardApiRequestAction({
    path: `/api/v1/study_sessions/${study_session_id}/users`,
    body,
    parentRequestKey: requestKey,
    requestKey: page,
  });
}

export function fetchStudySessionUsers(fetchParams, requestKey, page) {
  return standardApiRequestAction({
    path: '/api/v1/study_session_users',
    body: fetchParams,
    parentRequestKey: requestKey,
    requestKey: page,
  });
}

export function registerFreeStudySession(studySessionId, courseId) {
  return standardApiRequestAction({
    path: `/api/v1/study_sessions/${studySessionId}/users/free_register`,
    method: 'post',
    body: { course_id: courseId },
  });
}

export function confirmStudySessionPurchase(studySessionId, paymentIntentId, discountCodeId, courseId) {
  return standardApiRequestAction({
    path: `/api/v1/study_sessions/${studySessionId}/users/confirm_purchase`,
    method: 'post',
    body: { payment_intent_id: paymentIntentId, discount_code_id: discountCodeId, course_id: courseId },
  });
}

// ADMIN / MANAGER

export function updateStudySessionUser(id, body) {
  return standardApiRequestAction({
    path: `/api/v1/study_session_users/${id}`,
    method: 'patch',
    body,
  });
}

export function refundStudySessionUser(id, params) {
  return standardApiRequestAction({
    path: `/api/v1/study_session_users/${id}/refund`,
    method: 'put',
    body: params,
  });
}

export function deleteStudySessionUser(id) {
  return standardApiRequestAction({
    path: `/api/v1/study_session_users/${id}`,
    method: 'delete',
    destroy: { studySessionUsers: [id] },
  });
}

export function resendStudySessionUserEmail(id, email) {
  return standardApiRequestAction({
    path: `/api/v1/study_session_users/${id}/resend_email`,
    method: 'post',
    body: { email },
  });
}

export function addUserToStudySession(id, userId, courseId) {
  return standardApiRequestAction({
    path: `/api/v1/study_sessions/${id}/users/add_user`,
    method: 'post',
    body: { user_id: userId, course_id: courseId },
  });
}

export function syncStudySessionUserWithZoom(id) {
  return standardApiRequestAction({
    path: `/api/v1/study_session_users/${id}/zoom_sync`,
    method: 'put',
  });
}
